<template>
  <button class="scroll-btn" @click="backToTop">
    <a id="mainScrollBtn">
      <v-icon class="mb-0" large>{{ icons.mdiArrowUp }}</v-icon></a>
  </button>
</template>

<script>
import { mdiArrowUp } from "@mdi/js";

export default {
  name: "ButtonBackToTop",

  data: () => ({
    icons: {
      mdiArrowUp
    },

  }),
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    backToTop() {
      if (document.body.scrollTop > 0 ||
          document.documentElement.scrollTop > 0
        ) {
        window.scrollBy(0, -100);
        setTimeout(this.backToTop, 20);
      }
      if (document.body.scrollTop == 0){
        let arrow_up = document.getElementById("mainScrollBtn");
        arrow_up.style.display = "none";
      }
    },
    onScroll() {
      let arrow_up = document.getElementById("mainScrollBtn");
      if (window.scroll == 0) {
        arrow_up.style.display = "none";
      } else {
        arrow_up.style.display = "block";
      }
    },

  },
}
</script>

<style scoped lang="scss">
.scroll-btn {
  position: fixed;
  bottom: 60px;
  right: 0px;
  z-index: 99;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 15px;
  font-size: 30px;
  @include phone-only {
    font-size: 25px;
    right: -8px;
  }

  a {
    color: #04c;
    display: none;
    text-decoration: none;

    i {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
    }
  }
}
</style>